// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-market-guide-template-js": () => import("./../../../src/components/market-guide-template.js" /* webpackChunkName: "component---src-components-market-guide-template-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-download-js": () => import("./../../../src/pages/download.js" /* webpackChunkName: "component---src-pages-download-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-market-guide-js": () => import("./../../../src/pages/market-guide.js" /* webpackChunkName: "component---src-pages-market-guide-js" */),
  "component---src-pages-not-supported-js": () => import("./../../../src/pages/not-supported.js" /* webpackChunkName: "component---src-pages-not-supported-js" */),
  "component---src-pages-report-request-js": () => import("./../../../src/pages/report-request.js" /* webpackChunkName: "component---src-pages-report-request-js" */),
  "component---src-pages-report-request-thanks-js": () => import("./../../../src/pages/report-request-thanks.js" /* webpackChunkName: "component---src-pages-report-request-thanks-js" */),
  "component---src-pages-thanks-js": () => import("./../../../src/pages/thanks.js" /* webpackChunkName: "component---src-pages-thanks-js" */)
}

