/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
//bootstrap
import "bootstrap/dist/css/bootstrap.css"
import "./src/styles/master.scss"
import vhCheck from 'vh-check';
import { navigate } from 'gatsby';
import elementClosest from 'element-closest';
import isIE from './src/util/isIE';
elementClosest(window);


// gatsby-browser.js
export const onClientEntry = async () => {
    if (typeof window.IntersectionObserver === `undefined`) {
      await import(`intersection-observer`);
    }

    window.onbeforeunload = () => {
        window.scrollTo(0, 0);
    }

    window.onload = () => {
        window.scrollTo(0, 0);
        const { href } = window.location;

        if (isIE() && href.indexOf('not-supported') === -1) {
            // IE 10 or older => return version number
            navigate(`/not-supported`);
        }

        var termlyButton = document.createElement("div")
        termlyButton.innerHTML =
            '<button class="termly-cookie-preference-button" type="button" style="background: white; width: 165px; height: 30px; box-sizing: border-box; cursor: pointer; padding: 0; outline: none; position: fixed; bottom: 0; right:16px; background: #012834 !important; color: #FFF !important; font-size: 11px !important; border: none !important; z-index: 999; cursor: pointer;border-top-right-radius: 5px;font-weight: 500;" onclick="displayPreferenceModal()">Manage Cookie Preferences</button>'
        document.body.appendChild(termlyButton)

        document.addEventListener("click", e => {
            if (e.target.className === "termly-consent-banner-close-tag-c80607") {
                //do something
                window.location.reload()
            }
        })
    }
}
